'use client';

import { useState } from 'react';
import Button from '~/components/customComponents/buttons/Button';
import { useScopedI18n } from '~/locales/client';
import { Icon, IconName } from '../Icon';
import { colors } from '~/utils/tailwindUtils';

type CheckboxOption = {
  key: string;
  value: string;
  label: string;
};

export type CheckboxOptions = CheckboxOption[];

export interface CheckboxProps {
  checkboxOptions: CheckboxOptions;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value: (string | number)[];
}

const Checkbox = ({ checkboxOptions = [], onChange, value }: CheckboxProps) => {
  const t = useScopedI18n('component.checkbox');

  const onSetValue = (value: (string | number)[]) => {
    onChange(value);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const displayLimit = 6;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="space-y-2">
      <div className="flex flex-col space-y-2">
        {checkboxOptions
          .slice(0, isExpanded ? checkboxOptions.length : displayLimit)
          .map((option) => (
            <label
              key={option.key}
              className="flex items-center space-x-2 cursor-pointer"
            >
              <div className="relative">
                {/* Custom Checkbox */}
                <input
                  type="checkbox"
                  value={option.value}
                  checked={value.includes(option.value)}
                  onChange={() =>
                    onSetValue(
                      value.includes(option.value)
                        ? value.filter((v) => v !== option.value)
                        : [...value, option.value]
                    )
                  }
                  className="h-5 w-5 appearance-none border border-gray-400 rounded cursor-pointer checked:bg-red-500 checked:border-red-500 peer"
                />
                <Icon
                  name={IconName.Check}
                  className="absolute left-1/2 top-1/2 h-3 w-3 text-white transform -translate-x-1/2 -translate-y-2 opacity-0 peer-checked:opacity-100"
                  color={colors.white}
                  customStrokeWidth={3}
                />
              </div>
              <span className="text-sm text-gray-700">{option.label}</span>
            </label>
          ))}
      </div>

      {checkboxOptions.length > displayLimit && (
        <Button
          onClick={handleToggleExpand}
          buttonClassName="mt-4 text-secondary hover:underline"
          text={isExpanded ? t('show-less') : t('show-more')}
        />
      )}
    </div>
  );
};

export { Checkbox };
