import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { colors } from '~/utils/tailwindUtils';
import { Icon, IconName } from '../Icon';

type RadioOption = {
  key: string;
  value: string;
  label: string;
  sublabel?: string;
  icon?: string;
};

export type RadioOptions = RadioOption[];

export interface RadioProps {
  radioOptions: RadioOptions;
  withIcons?: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: string;
  name?: string;
  isBig?: boolean;
  direction?: 'flex-col' | 'flex-row' | null;
  type?: 'RID' | 'IRD'; // RID = Radio Icon Description, IRD = Icon Radio Description
}

const RadioWithIcon = ({
  option: { icon, value, label, sublabel },
  onChange,
  onBlur,
  selectedValue,
  type = 'IRD',
}: {
  option: RadioOption;
  onChange: RadioProps['onChange'];
  onBlur?: RadioProps['onBlur'];
  type: RadioProps['type'];
  selectedValue?: string;
}) => {
  const isChecked = selectedValue === value;
  const borderClass = isChecked
    ? 'border-red-500 bg-red-50'
    : 'border-gray-100 bg-white';

  const RadioInput = () => (
    <input
      checked={isChecked}
      value={value}
      id={label}
      name={label}
      type="radio"
      className="h-4 w-4 accent-red-600"
      onChange={onChange}
    />
  );

  return (
    <label htmlFor={label} className="cursor-pointer">
      <Flex
        className={`border rounded-xl p-6 max-w-full h-full gap-4 ${borderClass} ${
          type === 'IRD' ? 'flex-col md:max-w-60 md:gap-3' : 'items-center'
        }`}
        onBlur={onBlur}
      >
        {type === 'IRD' ? (
          <>
            <Flex className="w-full justify-between items-center">
              {icon && (
                <Icon
                  name={icon || IconName.Home}
                  color={isChecked ? colors.secondary : colors.primary}
                />
              )}
              <RadioInput />
            </Flex>
          </>
        ) : (
          <>
            <RadioInput />
            {icon && (
              <Icon
                name={icon}
                color={isChecked ? colors.secondary : colors.primary}
              />
            )}
          </>
        )}
        <Flex className="flex-col gap-1">
          <Text className="font-bold text-lg text-black">{label}</Text>
          {sublabel && (
            <Text className="text-sm text-gray-600 font-normal">
              {sublabel}
            </Text>
          )}
        </Flex>
      </Flex>
    </label>
  );
};

const Radio = ({
  radioOptions,
  onChange,
  onBlur,
  value,
  name,
  withIcons,
  isBig,
  direction = null,
  type,
}: RadioProps) => {
  const onSetValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const stackDirection =
    direction || (withIcons ? 'flex-col md:flex-row' : 'flex-col');

  return (
    <Flex role="radiogroup">
      <Flex
        className={twMerge(
          'w-full justify-center',
          isBig ? 'gap-6' : 'gap-2',
          stackDirection
        )}
      >
        {radioOptions.map((option, index) => (
          <Fragment key={index}>
            {withIcons ? (
              <RadioWithIcon
                key={option.key}
                option={option}
                selectedValue={value}
                onChange={onSetValue}
                onBlur={onBlur}
                type={type}
              />
            ) : (
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  checked={value === option.value}
                  onChange={onSetValue}
                  className="appearance-none border-2 border-gray-300 rounded-full checked:border-red-500 checked:border-4"
                />
                <Text>{option.label}</Text>
              </label>
            )}
          </Fragment>
        ))}
      </Flex>
    </Flex>
  );
};

export { Radio };
