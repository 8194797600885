'use client';

import { useEffect, useState } from 'react';
import { useI18n } from '~/locales/client';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import Button from '~/components/customComponents/buttons/Button';

export type NumberRangeProps = {
  value?: [number | string, number | string];
  onClick: (min: number | string, max: number | string) => void;
};

const NumberRange = ({ onClick, value }: NumberRangeProps) => {
  const t = useI18n();

  const [minPrice, setMinPrice] = useState<number | string>();
  const [maxPrice, setMaxPrice] = useState<number | string>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const isLargerThan800 =
    typeof window !== 'undefined' && window.innerWidth >= 768;

  const handleMinPriceChange = (e: any) => {
    const value = e?.target?.value;
    if (value === '') {
      setMinPrice('');
    } else if (!isNaN(parseInt(value))) {
      setMinPrice(parseInt(value));
    }
  };

  const handleMaxPriceChange = (e: any) => {
    const value = e?.target?.value;
    if (value === '') {
      setMaxPrice('');
    } else if (!isNaN(parseInt(value))) {
      setMaxPrice(parseInt(value));
    }
  };

  useEffect(() => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      setErrorMessage('wrong');
    } else {
      setErrorMessage(null);
    }
    if (!isLargerThan800) {
      onClick(minPrice ?? '', maxPrice ?? '');
    }
  }, [minPrice, maxPrice, isLargerThan800, onClick]);

  const minValue = () => minPrice ?? value?.[0] ?? '';
  const maxValue = () => maxPrice ?? value?.[1] ?? '';

  return (
    <Flex className="flex-col">
      <Flex className="flex-row justify-between gap-6">
        <Flex className="flex-col">
          <Text className="font-semibold text-xs mb-1">
            {t('component.numberRange.minimum')}
          </Text>
          <input
            className={`border rounded-full px-4 py-2 bg-gray-50 focus:ring-2 focus:ring-black ${
              errorMessage ? 'border-red-500' : 'border-transparent'
            }`}
            type="text"
            min="0"
            value={minValue()}
            onChange={handleMinPriceChange}
          />
        </Flex>
        <Flex className="flex-col">
          <Text className="font-semibold text-xs mb-1">
            {t('component.numberRange.maximum')}
          </Text>
          <input
            className={`border rounded-full px-4 py-2 bg-gray-50 focus:ring-2 focus:ring-black ${
              errorMessage ? 'border-red-500' : 'border-transparent'
            }`}
            type="number"
            value={maxValue()}
            onChange={handleMaxPriceChange}
          />
        </Flex>
      </Flex>
      {isLargerThan800 && (
        <Button
          onClick={() => onClick(minPrice!, maxPrice!)}
          disabled={!!errorMessage}
          buttonClassName="mt-6 border"
        >
          {t('component.numberRange.show-results')}
        </Button>
      )}
    </Flex>
  );
};

export default NumberRange;
