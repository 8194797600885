'use client';

import { useState } from 'react';
import { Icon, IconName } from '../../Icon';
import { InputProps, InputField } from '../FormField';

function PasswordInput({
  onChange,
  placeholder,
  value,
}: Omit<InputProps, 'type'>) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  return (
    <div className="relative pb-1">
      <InputField
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        classNames="pr-10"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <Icon
          className="cursor-pointer"
          onClick={handleTogglePassword}
          name={showPassword ? IconName.EyeClosed : IconName.EyeOpen}
          aria-label={showPassword ? 'hide password' : 'reveal password'}
        />
      </div>
    </div>
  );
}

export default PasswordInput;
