'use client';

import { CSSProperties, HTMLInputTypeAttribute, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { useI18n } from '~/locales/client';
import { Checkbox, CheckboxOptions } from '../Checkbox/Checkbox';
import Counter, { CounterProps } from '../Counter';
import NumberRange, { NumberRangeProps } from '../NumberRange/NumberRange';
import { Radio, RadioProps } from '../Radio/Radio';
import Select, { SelectProps } from '../Select/Select';
import PasswordInput from './types/password';

export type FormFieldType =
  | HTMLInputTypeAttribute
  | 'textarea'
  | 'counter'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'numberrange'
  | 'daterange';

export interface InputProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  type?: FormFieldType;
  placeholder?: string;
  classNames?: string;
  isDisabled?: boolean;
  counterProps?: CounterProps;
  selectProps?: Omit<SelectProps, 'onChange' | 'value'>;
  radioProps?: Omit<RadioProps, 'onChange' | 'value'>;
  checkboxOptions?: CheckboxOptions;
  numberRangeProps?: NumberRangeProps;
}

export interface FormFieldProps extends InputProps {
  onChange?: (e: any) => void;
  label?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  style?: CSSProperties;
}

export const InputField = ({
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  type,
  isDisabled,
  classNames,
}: Omit<FormFieldProps, 'label'>) => {
  return (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
      className={twMerge(
        'bg-gray-50 hover:bg-gray-100 text-black border border-transparent rounded-lg text-sm md:text-base font-medium min-w-full max-w-96 focus:outline-none focus:border-gray-300 px-5 py-2',
        classNames
      )}
    />
  );
};

export const TextAreaField = ({
  name,
  placeholder,
  onChange,
  value,
  classNames,
}: Omit<FormFieldProps, 'label' | 'type'>) => {
  return (
    <textarea
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={twMerge(
        'bg-gray-50 hover:bg-gray-100 text-black border border-transparent rounded-lg text-sm md:text-base font-medium min-w-full max-w-96 focus:outline-none focus:border-gray-300 px-5 py-2',
        classNames
      )}
    />
  );
};

const Inputfield = memo(
  ({
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    type,
    classNames,
    isDisabled,
    counterProps,
    selectProps,
    radioProps,
    checkboxOptions,
    numberRangeProps,
  }: InputProps) => {
    const t = useI18n();

    const handleEventChange = (event: React.ChangeEvent<any>) => {
      const value = event?.target?.value;
      if (onChange) {
        onChange(value ?? '');
      }
    };

    const handleValueChange = (value: any) => {
      if (onChange) {
        onChange(value ?? '');
      }
    };

    if (type === 'password') {
      return (
        <PasswordInput
          name={name}
          value={value}
          classNames={classNames}
          onChange={handleEventChange}
        />
      );
    } else if (type === 'textarea') {
      return (
        <TextAreaField
          name={name}
          placeholder={placeholder}
          value={value}
          classNames={classNames}
          onChange={handleEventChange}
        />
      );
    } else if (type === 'counter') {
      return (
        <Counter
          {...counterProps!}
          value={value}
          onChange={handleValueChange}
        />
      );
    } else if (type === 'select') {
      return (
        <Select
          {...selectProps!}
          name={name}
          value={value}
          placeholder={
            placeholder || t('component.form-field.select.placeholder')
          }
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'radio') {
      return (
        <Radio
          {...radioProps!}
          name={name}
          value={value}
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'checkbox') {
      return (
        <Checkbox
          checkboxOptions={checkboxOptions!}
          value={value}
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      );
    } else if (type === 'numberrange') {
      return numberRangeProps && <NumberRange {...numberRangeProps} />;
    }

    return (
      <InputField
        name={name}
        classNames={classNames}
        placeholder={placeholder}
        value={value}
        onChange={handleEventChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
      />
    );
  }
);

Inputfield.displayName = 'Inputfield';

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  errorMessage,
  classNames,
  required = false,
  isInvalid,
  onChange,
  style,
  ...props
}) => {
  return (
    <Flex className={twMerge('flex-col mb-7', classNames)} style={style}>
      <label className="text-black font-semibold text-xs mb-1">{label}</label>
      <Inputfield
        {...props}
        name={name}
        onChange={onChange}
        classNames={isInvalid ? 'border-red-500' : 'border-transparent'}
      />
      {errorMessage && (
        <Text className="text-red-500 text-xs font-medium mt-1">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

type FormFieldLayoutProps = {
  label: string | React.ReactElement;
  errorMessage?: string;
  style?: CSSProperties;
  classNames?: string;
  children: React.ReactNode;
};

export const FormFieldLayout = ({
  label,
  errorMessage,
  style,
  classNames,
  children,
}: FormFieldLayoutProps) => {
  return (
    <Flex className={twMerge('flex-col mb-7', classNames)} style={style}>
      <label className="text-black font-semibold text-xs mb-1">{label}</label>
      {children}
      {errorMessage && (
        <Text className="text-red-500 text-xs font-medium mt-1">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

export default FormField;
