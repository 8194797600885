'use client';

import Flex from '../customComponents/flex/Flex';

export type CounterProps = {
  amount: number;
  name?: string;
  value: number;
  withSlider?: boolean;
  min?: number;
  max: number;
  onChange?: (v: number) => void;
  ticker?: string;
  maxWidth?: number | 'full';
};

function NumberSection({
  value,
  onChange,
  placeholder,
  max = 50,
  ticker,
  name,
}: {
  onChange?: (v: number) => void;
  value: number;
  placeholder?: string;
  max?: number;
  ticker?: string;
  name?: string;
}) {
  const format = (val: number) => (ticker ? ticker + val : val);
  const parse = (val: string) => Number(ticker ? val.replace(ticker, '') : val);

  const onChangeHandler = (value: string) => {
    onChange && onChange(parse(value));
  };
  const numberInputValue = format(value);
  return (
    <div className="flex flex-col w-full">
      <input
        type="number"
        value={numberInputValue}
        onChange={(e) => onChangeHandler(e.target.value)}
        max={max}
        name={name}
        placeholder={placeholder}
        className="bg-gray-50 hover:bg-gray-100 text-black border border-transparent rounded-lg text-sm md:text-base font-medium max-w-96 focus:outline-none focus:border-gray-300 px-2 py-2"
      />
    </div>
  );
}

const Counter = ({
  amount,
  value,
  max,
  min = 0,
  onChange,
  name,
  ticker,
  maxWidth,
}: CounterProps) => {
  const handleIncrease = () => {
    let res = Number(value + amount);
    res = res >= max ? max : res;

    onChange && onChange(res);
  };

  const handleDecrease = () => {
    let res = value - amount;
    res = res > min ? res : min;

    onChange && onChange(res);
  };

  return (
    <Flex
      className="w-full items-center justify-between"
      style={{ maxWidth: maxWidth + 'px' }}
    >
      <button
        onClick={handleDecrease}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleDecrease();
          }
        }}
        type="button"
        className="rounded-full min-w-10 h-10 max-h-10 bg-white hover:bg-gray-100 flex items-center justify-center border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        -
      </button>
      <NumberSection
        onChange={onChange}
        value={value}
        // max={max}
        ticker={ticker}
        name={name}
      />
      <button
        onClick={handleIncrease}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleIncrease();
          }
        }}
        className="rounded-full min-w-10 h-10 max-h-10 bg-white hover:bg-gray-100 flex items-center justify-center border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        type="button"
      >
        +
      </button>
    </Flex>
  );
};

export default Counter;
